import { configureStore } from "@reduxjs/toolkit";
import assistantReducer from './assistantSlice'
import authReducer from './authSlice'
import chatsSlice from "./chatSlice";

export const store = configureStore({
    reducer: {
        assistant: assistantReducer,
        auth: authReducer,
        chats: chatsSlice,
    },
})