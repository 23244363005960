import s from './Modal.module.scss'

export const Modal = ({ isOpen, setIsOpen, children, setIsSignIn }) => {
    const closeWindow = () => {
        setIsOpen(false)

        setTimeout(() => setIsSignIn('signIn'), 300)
        
    }
    return (
        <div
            className={isOpen ? `${s.modal} ${s.modal_open}` : s.modal}
            onClick={() => closeWindow(false)}>
            <div 
                className={isOpen ? `${s.modal_content} ${s.modal_content_open}` : s.modal_content}
                onClick={ e => e.stopPropagation()}>
                    {children}
            </div>
        </div>
    )
}