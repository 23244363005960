import s from './DeleteAccount.module.scss'
import { ReactComponent as Warning } from '../../assets/warning.svg'
import { FirebaseClient } from '../../FirebaseClient/FirebaseClient'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const DeleteAccount = ({ setIsOpen }) => {
    const { authError } = useSelector(state => state.auth)
    const firebase = new FirebaseClient()
    const deleteAccount = async () => {
        await firebase.deleteAccount()
    }
    const { t } = useTranslation()

    return (
        <div className={s.delete}>
            <div className={s.icon}>
                <Warning />
            </div>
            <div className={s.title}>
                {t('isDelete')}
            </div>
            <div className={s.text}>
                {t('deleteText')}
            </div>
            <div className={s.buttons}>
                <button onClick={() => setIsOpen(false)} className={s.submit_button}>
                    {t('cancel')}
                </button>
                <button onClick={() => deleteAccount()} className={`${s.submit_button} ${s.grey}`} >
                    {t('delete')}
                </button>
            </div>
            {authError && <div className={s.error}>
                <Warning />
                <span>{t(`errors.${authError.code}`)}</span>
            </div>}
        </div>
    )
}