import s from './VerifyEmail.module.scss'
import { ReactComponent as Message } from '../../assets/message-send.svg'
import { MainButton } from '../../Components/MainButton/MainButton'
import { useTranslation } from 'react-i18next'

export const VerifyEmail = () => {
    const { t } = useTranslation()
    return (
        <div className={s.message_container}>
            <Message />
            <div>
                {t('verifyEmail')}
            </div>
            <div>
                {t('verifyEmailText')}
            </div>
            <div>
            {   t('verifyEmailText2')}
            </div>
            <div className={s.button}>
                <MainButton 
                    text= {t('goAsist')}
                    route='reload' //for page reload
                />
            </div>
            <div>
            {t('haventEmail')}
            </div>
            <div>
            {t('resendEmail')}
            </div>
        </div>
    )
}