import { useTranslation } from 'react-i18next'
import { MainButton } from '../../Components/MainButton/MainButton'
import s from './Main.module.scss'
import ddd from './back.mp4'

export const Main = () => {

    const { t } = useTranslation()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return (
        <section className={s.main}>
            <div className={s.main_body}>
                <h1 className={s.title}>
                    {t('mainTitle')}
                </h1>
                <div className={s.text}>
                    {t('mainTitleSecond')}
                </div>
                <div className={s.buttons}>
                    <MainButton
                        text={t('TelegramBotsButton')}
                        route={'/bots'} />
                    <MainButton
                        text={t('WebAssistantButton')}
                        route={'/webassistant'} />
                    <MainButton
                        text={t('MobileAppButton')}
                        route={'/mobileapp'} />
                </div>
            </div>
            <video autoPlay muted loop playsInline className={s.video}>
                <source src={ddd} type="video/mp4" />
            </video>
        </section>
    )
}