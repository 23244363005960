import { useEffect, useRef, useState } from 'react'
import { ReactComponent as User } from '../../../assets/person.svg'
import s from './UserWindow.module.scss'
import { FirebaseClient } from '../../../FirebaseClient/FirebaseClient'
import { useDispatch } from 'react-redux'
import { setUser } from '../../../store/authSlice'
import { resetChatMessages } from '../../../store/assistantSlice'
import { Modal } from '../../Modal/Modal'
import { DeleteAccount } from '../../DeleteAccount/DeleteAccount'
import { useTranslation } from 'react-i18next'

export const UserWindow = () => {
    const optionBodyRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [, setIsSignIn] = useState('signIn')
    const firebase = new FirebaseClient()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const logout = () => {
        firebase.signOut()
        dispatch(setUser(null))
        dispatch(resetChatMessages())
    }

    useEffect(() => {
        const closeOptionBody = (event) => {
            if (optionBodyRef.current && !optionBodyRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        };
        document.addEventListener('click', closeOptionBody)

        return () => {
            document.removeEventListener('click', closeOptionBody)
        }
    }, [])
    return (
        <>
        <div
            ref={optionBodyRef}
            onClick={() => setIsOpen(!isOpen)}
            className={s.user_container}>
            <div className={s.icons_container}>
                <User />
            </div>

            {isOpen ?
                <div className={s.activities}>
                    <div onClick={() => logout()}>
                        {t('logout')}
                    </div>
                    <div onClick={() => setIsModal(true)}>
                        {t('deleteAccount')}
                    </div>
                </div> :
                null}                    
        </div>
        <Modal             
                    isOpen={isModal} 
                    setIsOpen={setIsModal}
                    setIsSignIn={setIsSignIn}
                    children={<DeleteAccount setIsOpen={setIsModal}/>}/>
        </>
        
    )
}