import { useState } from 'react'
import s from './InputArea.module.scss'
import { InputText } from './InputText'
import { InputVoice } from './InputVoice'

export const InputArea = () => {

    const [isVoice, setIsVoice] = useState(true) 
    const [isText, setIsText] = useState(false)

    return (
        <div className={s.input_body}>
            <InputText isVoice={isVoice} isText={isText} setIsText={setIsText}/>
            <InputVoice isVoice={isVoice} setIsVoice={setIsVoice} isText={isText}/>
        </div>
    )
}