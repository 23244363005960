import { NavLink } from 'react-router-dom'
import s from './Footer.module.scss'
import { useTranslation } from 'react-i18next'

export const Footer = () => {

    const { t } = useTranslation()

    return (
        <div className={s.footer_container}>
            <div className={s.items}>
                <NavLink to={'/termsofuse'}>
                    {t('termsOfUse')}
                </NavLink>
                <NavLink to={'/privacypolicy'}>
                    {t('privacyPolicy')}
                </NavLink>
                <NavLink to={'/aboutus'}>
                    {t('aboutUs')}
                </NavLink>
                <NavLink to={'/contacts'}>
                    {t('contacts')}
                </NavLink>
            </div>
            <div className={s.rights}>
                © 2023. All Rights Reserved
            </div>
        </div>
    )
}