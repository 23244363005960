const botName = "kiryl"

export const chatsForRequest = [
    {
        name: "assistant",
        role: "assistant",
        bot_name: botName
    },
    {
        name: "architect",
        role: "architect",
        bot_name: botName
    },
    {
        name: "business_analyst",
        role: "business_analyst",
        bot_name: botName
    },
    {
        name: "financial_consultant",
        role: "financial_consultant",
        bot_name: botName
    },
    {
        name: "recruiter",
        role: "recruiter",
        bot_name: botName
    },
    {
        name: "project_manager",
        role: "project_manager",
        bot_name: botName
    },
    {
        name: "legal_consultant",
        role: "legal_consultant",
        bot_name: botName
    },
    {
        name: "marketing specialist",
        role: "marketing specialist",
        bot_name: botName
    },
    {
        name: "realtor",
        role: "realtor",
        bot_name: botName
    },
    {
        name: "engineer",
        role: "engineer",
        bot_name: botName
    },
    {
        name: "programmer",
        role: "programmer",
        bot_name: botName
    },
    {
        name: "teacher",
        role: "teacher",
        bot_name: botName
    },
    {
        name: "writer",
        role: "writer",
        bot_name: botName
    },
    {
        name: "codex_assistant",
        role: "codex_assistant",
        bot_name: botName
    },
    {
        name: "audio_stenographer",
        role: "audio_stenographer",
        bot_name: botName
    },
    {
        name: "historian",
        role: "historian",
        bot_name: botName
    },
    {
        name: "frantsysk_skaryna",
        role: "frantsysk_skaryna",
        bot_name: botName
    },
    {
        name: "dictor",
        role: "dictor",
        bot_name: botName
    }
]