import s from './Answer.module.scss'
import { ReactComponent as Volume } from './volume.svg'
import { ReactComponent as Copy } from './copy.svg'
import { useRef } from 'react';

export const Answer = ({ e, index }) => {
    const audioRef = useRef(null);

    const togglePlay = () => {
        const audioElement = audioRef.current;
        if (audioElement.paused) {
            pauseAllAudioPlayers()
            audioElement.play();
        } else {
            audioElement.pause()
        }
    };

    const pauseAllAudioPlayers = () => {
        const audioPlayers = document.querySelectorAll('audio');
        audioPlayers.forEach((player) => {
            if (player !== audioRef.current) {
                player.pause();
                player.currentTime = 0
            }
        });
    };


    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .catch((error) => {
                alert('Failed to copy text to clipboard:', error);
            });
    };

    return (
        <>
            {!e.isAnswer && e.audio
                ? <div className={s.audio_container}>
                    <audio ref={audioRef} src={e.audio} controls>
                        <source type="audio/mpeg" />
                    </audio>
                </div>
                : <div className={e.isAnswer ? s.answer : s.question} key={index}>
                    <div>{e.text}</div>
                    {e.isAnswer
                        ? <div className={s.bottom_bar}>
                            <Volume onClick={togglePlay} />
                            <Copy onClick={() => handleCopy(e.text)} />
                            <audio src={e.audio} ref={audioRef} />
                        </div>
                        : null}
                </div>}

        </>

    )
}