import { useTranslation } from 'react-i18next'
import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './PrivacyPolicy.module.scss'
import { Footer } from '../../Components/Footer/Footer'


export const PrivacyPolicy = () => {

    const { t } = useTranslation()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const writeText = () => {
        const textItems = [];
        for (let i = 1; i < 39; i++) {
            textItems.push(
                <div className={s.text_item} key={i}>
                    {t(`privacyPolicyText${i}`)}
                </div>
            );
        }
        return textItems;
    }
    
    return (
        <section className={s.wrapper}>
            <div className={s.about_container}>
                <NavigationBar mainPage={t('NavigationMain')} pageName={t('privacyPolicy')} />
                <div className={s.about_content}>
                    <div className={s.title}>
                    {t('privacyPolicy')}
                    </div>
                    <div className={s.text}>
                        {writeText()}
                    </div>
                </div>
                <Footer />
            </div>
        </section>
    )
}