import { useState } from "react";
import s from './SignIn.module.scss'
import { ReactComponent as Google } from '../../assets/google.svg';
import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Warning } from '../../assets/warning.svg';
import { TextWithLine } from "./TextWithLine";
import { FirebaseClient } from "../../FirebaseClient/FirebaseClient";
import { useDispatch, useSelector } from "react-redux";
import { setAuthError, setUser } from "../../store/authSlice";
import { getAllChats } from "../../store/chatSlice";
import { useTranslation } from "react-i18next";

export const SignIn = ({ setIsSignIn, setIsOpen }) => {
    const { authError } = useSelector(state => state.auth)
    const [emailLog, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { t } = useTranslation()
    const firebase = new FirebaseClient()
    const dispatch = useDispatch()

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        dispatch(setAuthError(null))
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
        dispatch(setAuthError(null))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const user = await firebase.signInWithEmailAndPassword(emailLog, password)
        if (user !== undefined) {
            dispatch(setUser(user))
            dispatch(getAllChats(user.uid))
            setEmail('')
            setPassword('')
            setIsOpen(false)
            dispatch(setAuthError(null))
        }

    }

    const submitWithGoogle = async () => {
        const user = await firebase.signInWithGoogle()
        if (user !== undefined) {
        dispatch(setUser(user))
        dispatch(setAuthError(null))
        await dispatch(getAllChats(user.uid))
        }
    }

    return (
        <div className={s.sign}>
            <div className={s.cross_container}>
                <Cross onClick={() => setIsOpen(false)} />
            </div>
            
            <div className={s.title}>{t('loginTitle')}</div>
            <div onClick={() => submitWithGoogle()} className={s.google_button}>
                <Google />
                <span>{t('loginWithGoogle')}</span>
            </div>
            <TextWithLine text={t('or')} />
            <form className={s.form} onSubmit={handleSubmit}>
                <div className={s.input_container}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={emailLog}
                        onChange={handleEmailChange}
                        placeholder="example@gmail.com"
                    />
                </div>
                <div className={s.input_container}>
                    <label htmlFor="password">{t('password')}:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder= {t('passwordValidation')}
                    />
                    {authError && <div className={s.error}>
                        <Warning />
                        <span>{t(`errors.${authError.code}`)}</span>
                    </div>}
                </div>
                <div className={s.forgot_password}>
                    <span onClick={() => setIsSignIn('forgot')}>{t('forgotPassword')}</span>
                </div>
                <button className={s.submit_button} type="submit">{t('login')}</button>
            </form>
            <div className={s.signup}>
                <span>{t('accountFalse')}</span>
                <span onClick={() => setIsSignIn('signUp')} className={s.up_link}>{t('signUp')}</span>
            </div>
        </div>
    );
}