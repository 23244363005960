import s from './OtherPlatforms.module.scss'
import { ReactComponent as WebIcon } from './web.svg'
import { ReactComponent as TgIcon } from './tg.svg'
import { ReactComponent as AppIcon } from './app.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'



export const OtherPlatforms = ({ activePlatform }) => {
    
    const { t } = useTranslation()

    const navigate = useNavigate()
    const navigateToMain = (path) => {
        navigate(path)
    }

    return (
        <div className={s.platform_container}>
            <h1>
                {t('PlatformsTitle')}
            </h1>
            <div className={s.platforms}>
                {activePlatform !== 'web' && <div onClick={() => navigateToMain('/webassistant')} className={s.item}>
                    <WebIcon />
                    <span>
                        {t('WebAssistantButton')}
                    </span>
                </div>}
                {activePlatform !== 'bots' && <div onClick={() => navigateToMain('/bots')} className={s.item}>
                    <TgIcon />
                    <span>
                        {t('TelegramBotsButton')}
                    </span>
                </div>}
                {activePlatform !== 'mobileapp' && <div onClick={() => navigateToMain('/mobileapp')} className={s.item}>
                    <AppIcon />
                    <span>
                        {t('MobileAppButton')}
                    </span>
                </div>}
            </div>
        </div>
    )
}
