import { useTranslation } from 'react-i18next'
import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './MobileApp.module.scss'
import iphone from './iPhone.png'
import { OtherPlatforms } from '../../Components/OtherPlatforms/OtherPlatforms'
import { Footer } from '../../Components/Footer/Footer'
import { MainButton } from '../../Components/MainButton/MainButton'

export const MobileApp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const { t } = useTranslation()    

    return (
        <div className={s.wrapper}>
            <div className={s.mobile_container}>
                <NavigationBar mainPage={t('NavigationMain')} pageName={t('MobileAppButton')} />
                <div className={s.content}>
                    <div className={s.title}>
                        <h1>
                            {t('MobileAppTitle')}
                        </h1>
                        <div className={s.button_container}>
                            <a href='https://apps.apple.com/pl/app/belsint/id6465291133' rel="noreferrer" target='_blank'>
                                <MainButton text={'IOS'} />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.ssrlab.assistant&pcampaignid=web_share' rel="noreferrer" target='_blank'>
                                <MainButton text={'ANDROID'} />
                            </a>
                        </div>

                    </div>
                    <div className={s.img}>
                        <div className={s.pic}>
                            <img src={iphone} alt="" />
                        </div>
                    </div>
                </div>
                <OtherPlatforms activePlatform={'mobileapp'} />
                <Footer />
            </div>
        </div>

    )
}