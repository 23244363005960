import { useTranslation } from 'react-i18next'
import s from './InputArea.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchText, setTextMessages } from '../../store/assistantSlice'
import { ReactComponent as Send } from '../../assets/send.svg'

export const InputText = ({isVoice, isText, setIsText}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { status } = useSelector(state => state.assistant)
    const [inputText, setInputText] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputText.trim() !== '' && status !== 'loading') {
            dispatch(setTextMessages({text: inputText, isAnswer: false}))
            dispatch(fetchText(inputText))
            setInputText('');
        }
    };
    const sendMessageFromBtn = () => {
        if (inputText.trim() !== '' && status !== 'loading') {
            dispatch(setTextMessages({text: inputText, isAnswer: false}))
            dispatch(fetchText(inputText))
            setInputText('');
        }
    }
    useEffect(() => {
        if(inputText === '') {
            setIsText(false)
        } else setIsText(true)
    }, [inputText, setIsText])

    useEffect(() => {
        if (!isVoice) {
            setInputText('')
        }
    }, [isVoice])

    return (
        <>
        <input style={!isVoice ? {textAlign: 'center'} : null} className={s.input_area}
                placeholder={isVoice ? t('InputPlaceHolderText') : t('InputPlaceHolderVoice')}
                type="text"
                readOnly={!isVoice}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyDown} />
        {isText && <Send onClick={sendMessageFromBtn} className={s.send}/>}
        </>
    )
}