import s from './SelectBotsRole.module.scss'
import { ReactComponent as Arrow } from '../../assets/Arrow.svg'
import { ReactComponent as MobileRoleIcon } from '../../assets/mobileRoleIcon.svg'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetChatMessages, setBotsRole } from '../../store/assistantSlice'
import { useTranslation } from 'react-i18next'
import { setCurrentChatId } from '../../store/chatSlice'

export const SelectBotsRole = () => {
    const { allRoles, botsRole } = useSelector(state => state.assistant)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isShowOptionBody, setIsShowOptionBody] = useState(false)
    const optionBodyRef = useRef(null);
    const selectBotsRole = (role) => {
        dispatch(setBotsRole(role))
        dispatch(resetChatMessages())
        dispatch(setCurrentChatId(role))
        setIsShowOptionBody(false)
    }
    
    const closeOptionBody = (event) => {
        if (optionBodyRef.current && !optionBodyRef.current.contains(event.target)) {
            setIsShowOptionBody(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeOptionBody);

        return () => {
            document.removeEventListener('click', closeOptionBody);
        };
    }, []);
    return (
        <div ref={optionBodyRef} className={s.select_container}>
            <div
                onClick={() => setIsShowOptionBody(!isShowOptionBody)}
                className={s.select}
            >
                <span>
                    {t(`roles.${botsRole.id}`)}
                </span>
                <Arrow
                    className={isShowOptionBody ? s.arrowIsUp : null}
                />
            </div>
            <div className={s.mobile_role_icon}>
                <MobileRoleIcon
                    onClick={() => setIsShowOptionBody(!isShowOptionBody)}
                />
            </div>
            {isShowOptionBody &&
                <div className={s.option_body}>
                    {allRoles?.map((role, index) =>
                        <div key={index} onClick={() => selectBotsRole(role)} className={s.option}>
                            {t(`roles.${role.id}`)}
                        </div>)}
                </div>}
        </div>
    )
}