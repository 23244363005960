import { useNavigate } from 'react-router-dom'
import s from './Header.module.scss'
import { SelectLanguage } from './SelectLanguage/SelectLanguage'
import { ReactComponent as Logo } from './logo.svg'
import { Login } from '../LogIn/Login'
import { useSelector } from 'react-redux'
import { UserWindow } from './UserWindow/UserWindow'

export const Header = () => {
    const navigate = useNavigate()
    const { user } = useSelector(state => state.auth)
    const navigateToMain = () => {
        navigate('/')
    }
    return (
        <div className={s.container}>
            <div className={s.header}>
                <div onClick={navigateToMain} className={s.logo}>
                    <Logo />
                </div>
                <div className={s.icons_container}>
                    <SelectLanguage />
                    {user ? <UserWindow /> : <Login />}
                </div>
            </div>
        </div>
    )
}