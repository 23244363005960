import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resetErrorMessage, setTextMessages } from "./assistantSlice";
import { chatsForRequest } from "./helper";


export const createChats = createAsyncThunk(
  'chat/createChats',
  async (userId, { rejectWithValue, dispatch }) => {
      try {
          const results = [];
          for (let i = 0; i < chatsForRequest.length; i++) {
              const el = chatsForRequest[i];
              await new Promise(resolve => setTimeout(resolve, 100))

              const response = await fetch('https://ml1.ssrlab.by/chat-api/chat', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'x-user-id': userId
                  },
                  body: JSON.stringify(el),
              });

              if (!response.ok) {
                  throw new Error(response.statusText);
              }

              const data = await response.json();
              results.push(data);
              dispatch(resetErrorMessage());
          }
          return results;
      } catch (error) {
          return rejectWithValue(error.message);
      }
  }
)
export const getAllChats = createAsyncThunk(
    'chat/getAllChats',
    async (userId, {rejectWithValue, dispatch}) => {
      try {
        const response = await fetch(`https://ml1.ssrlab.by/chat-api/chats`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-user-id': userId,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        })
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        const data = await response.json()
        if(data.chats.length === 0) {
          await dispatch(createChats(userId))
        }
        return data
      } catch (error) {
        return rejectWithValue(error.message)
      }
    }
  )

  export const getChatMessages = createAsyncThunk(
    'chat/getChatMessages',
    async (chatId, {rejectWithValue, getState, dispatch}) => {
      try {
        const state = getState()
        const response = await fetch(`https://ml1.ssrlab.by/chat-api/message/${chatId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'chat_id': chatId,
                'x-user-id': state.auth.user.uid,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        })
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        const data = await response.json()
        data.forEach(dialog => {
          dispatch(setTextMessages({
            text: dialog.message_text, 
            audio: dialog.message_voice_url,
            isAnswer: false
          }))
          dispatch(setTextMessages({
            text: dialog.response_text, 
            audio: dialog.response_voice_url,
            isAnswer: true}))
        })
      } catch (error) {
        return rejectWithValue(error.message)
      }
    }
  )
const chatsSlice = createSlice({
    name: 'chats',
    initialState: {
        error: null,
        status: null,
        allChats: null,
        currentChatId: null
    },
    reducers: {
      setCurrentChatId: (state, action) => {
        const currentChat = state.allChats?.chats.find(e => e.role === action.payload.id)
        state.currentChatId = currentChat?.chat_id
      },
    },  
    extraReducers: (builder) => {
        builder
          .addCase(getAllChats.pending, (state) => {
            state.status = 'loading';
            state.error = null
          })
          .addCase(getAllChats.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.allChats = action.payload
          })
          .addCase(getAllChats.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
          })
    }
})

export const {
  setCurrentChatId
} = chatsSlice.actions
export default chatsSlice.reducer

