import s from './EmailIsSend.module.scss'
import { ReactComponent as Email } from '../../assets/email.svg'
import { useTranslation } from 'react-i18next'

export const EmailIsSend = ({ setIsSignIn, setIsOpen }) => {
    const { t } = useTranslation()
    const closeWindow = () => {
        setIsOpen(false)
        setTimeout(() => setIsSignIn('signIn'), 300)
    }
    return (
        <div className={s.email}>
            <div className={s.icon}>
                <Email />
            </div>
            <div className={s.title}>
                {t('sendedEmail')}
            </div>
            <div className={s.text}>
                {t('checkEmail')}
            </div>
            <button onClick={() => closeWindow()} className={s.submit_button} >
                {t('okButton')}
            </button>
        </div>
    )
}