import { useNavigate } from "react-router-dom"
import s from './MainButton.module.scss'

export const MainButton = ({text = 'button', route}) => {
    
    const navigate = useNavigate()
    const navigateTopage = () => {
        if(route === 'reload') {
            window.location.reload()
        }
        navigate(route);
      };

    return (
        <button className={s.button} onClick={navigateTopage}>
            {text}
        </button>
    )
}