import { useEffect, useState } from 'react'
import { ReactComponent as Mic } from '../../assets/mic.svg'
import { ReactComponent as Stop } from '../../assets/stop.svg'
import s from './InputArea.module.scss'
import { fetchVoice, setAudioVoice } from '../../store/assistantSlice'
import { useDispatch, useSelector } from 'react-redux'

export const InputVoice = ({isVoice, setIsVoice, isText}) => {
    
    
    const [audio, setAudio] = useState(null)   
    const [time, setTime] = useState({ min: 0, seconds: 0 });
    const dispatch = useDispatch()
    const { status } = useSelector(state => state.assistant)

    useEffect(() => {  
      if (!isVoice) {
        const interval = setInterval(() => {
          setTime(prevTime => {
            const newSeconds = prevTime.seconds + 1;
            const newMin = prevTime.seconds >= 59 ? prevTime.min + 1 : prevTime.min;
            return {
              seconds: newSeconds >= 60 ? 0 : newSeconds,
              min: newMin
            };
          });
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }, [isVoice]);

    useEffect(() => {
        if (!audio) {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
              const mediaRecorder = new MediaRecorder(stream)
              setAudio(mediaRecorder);
            })
            .catch(error => {
              setAudio(null)
            });
        }
    }, [audio])
  
    if (!audio) {
      return null
    }
    const audioChunks = []
    audio.ondataavailable = (event) => {
      audioChunks.push(event.data)
    };
    audio.onstart = () => {
      setIsVoice(false)
    }
    audio.onstop = () => {
      const audioBlob = new Blob(audioChunks);
      setIsVoice(true)
      setTime({ min: 0, seconds: 0 });
      dispatch(setAudioVoice(URL.createObjectURL(audioBlob)))
      dispatch(fetchVoice(audioBlob))
    }

    

    return (
        <>
        {
            !isText ? isVoice ? <div className={s.mic_container}><Mic onClick={ () => status !== 'loading' && audio.start()} className={s.mic} /></div> 
            : <div className={s.mic_container}>
              <div className={s.time}>
                <div className={s.circle}></div>{time.min < 10 ? `0${time.min}` : time.min}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
                </div>
              <Stop onClick={() => audio.stop()} className={s.mic} />
              </div> 
        : null}
        </>
    )
}