import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: undefined,
    authError: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setAuthError: (state, action) => {
      state.authError = action.payload
    }
  }
})

export const { setUser, setAuthError } = authSlice.actions
export default authSlice.reducer

