import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth'
import { store } from '../store/index'
import { setAuthError, setUser } from "../store/authSlice"
import { resetChatMessages } from "../store/assistantSlice"

export class FirebaseClient {
    constructor() {
      this.firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID
      }
      this.app = initializeApp(this.firebaseConfig)
      this.auth = getAuth(this.app)
    }

async isAuth() {
  const authStateChanged = await new Promise((resolve) => {
    const unsubscribe = this.auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    })
  })

  if (authStateChanged) {
    return authStateChanged
  } else {
    return null
  }
}

    async signInWithEmailAndPassword(email, password) {
      try {
        const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
        const user = userCredential.user
        return user
      } catch (error) {
        const {code, message} = error
        store.dispatch(setAuthError({code, message}))
      }
    }
  
    async signUpWithEmailAndPassword(email, password) {
      try {
        const userCredential = await createUserWithEmailAndPassword(this.auth, email, password)
        const user = userCredential.user
        await sendEmailVerification(user)
        return user
      } catch (error) {
        const {code, message} = error
        store.dispatch(setAuthError({code, message}))
      }
    }
    
    async signInWithGoogle() {
      try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(this.auth, provider)
        const user = result.user

        return user;
      } catch (error) {
        if (error.code === 'auth/popup-closed-by-user' || 'auth/cancelled-popup-request') {
          return
        } else {
          throw error
        }
      }
    }

    async signOut() {
      try {
        await this.auth.signOut()
      } catch (error) {
        throw error
      }
    }
    async deleteAccount() {
      try {
        const user = this.auth.currentUser
          await user.delete()
      } catch (error) {
          const {code, message} = error
          store.dispatch(setAuthError({code, message}))
      }
    }
    async sendPasswordReset(email) {
      try {
        await sendPasswordResetEmail(this.auth, email)
        store.dispatch(setUser(null))
        store.dispatch(resetChatMessages())
      } catch (error) {
        const {code, message} = error
          store.dispatch(setAuthError({code, message}))
        }
    }
  }



//   signInWithEmailAndPasswor('example@example.com', 'password123');