import ales from '../../assets/ales.png'
import alesya from '../../assets/alesya.png'
import alena from '../../assets/alena.png'
import boris from '../../assets/boris.png'
import kirill from '../../assets/kirill.png'
import vasil from '../../assets/vasil.png'
import vasilina from '../../assets/vasilina.png'
import marya from '../../assets/marya.png'


export const botsArray = [
    {
        src: ales,
        name: 'ales',
        dev: 'voiceStab',
        link: 'https://t.me/AIAlesBot'
    },
    {
        src: alesya,
        name: 'alesya',
        dev: 'voiceStab',
        link: 'https://t.me/AIAlesiabot'
    },
    {
        src: alena,
        name: 'alena',
        dev: 'voiceStab',
        link: 'https://t.me/AIAlenabot'
    },
    {
        src: boris,
        name: 'boris',
        dev: 'voiceStab',
        link: 'https://t.me/AIBorisBot'
    },
    {
        src: kirill,
        name: 'kirill',
        dev: 'voiceStab',
        link: 'http://t.me/AIKirylBot'
    },
    {
        src: marya,
        name: 'marya',
        dev: 'voiceStab',
        link: 'https://t.me/AIMaryjaBot'
    }
]

export const botsArrayWithRole = [
    {
        src: vasil,
        name: 'vasil',
        dev: 'role',
        link: 'https://t.me/asistentmy_bot'
    },
    {
        src: vasilina,
        name: 'vasilina',
        dev: 'inDev',
        link: null
    },
]