import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getRoles } from './store/assistantSlice';
import { FirebaseClient } from './FirebaseClient/FirebaseClient';
import { setUser } from './store/authSlice';
import { Auth } from './Components/Auth/Auth';
import { Loader } from './Components/Loading/Loading';
import { getAllChats } from './store/chatSlice';

export const App = () => {

  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      const firebase = new FirebaseClient()
      const user = await firebase.isAuth()
      dispatch(setUser(user))
      dispatch(getRoles())

      if (user) {
        await dispatch(getAllChats(user.uid))
      }
    }
    fetchData()
  }, [dispatch])

  return (
    <div className='app'>
      {user === undefined
        ? <Loader />
        : <Auth />}
    </div>
  )
}


