import { Route, Routes } from "react-router-dom"
import { Main } from "../../Pages/Main/Main"
import { Bots } from "../../Pages/Bots/Bots"
import { MobileApp } from "../../Pages/MobileApp/MobileApp"
import { WebAssistant } from "../../Pages/Web/WebAssistant"
import { AboutUs } from "../../Pages/About us/AboutUs"
import { Contacts } from "../../Pages/Contacts/Contacts"
import { PrivacyPolicy } from "../../Pages/PrivacyPolicy/PrivacyPolicy"
import { TermsOfUse } from "../../Pages/TermsOfUse/TermsOfUse"
import { useDispatch, useSelector } from "react-redux"
import { Header } from "../Header/Header"
import { VerifyEmail } from "../../Pages/VerifyEmail/VerifyEmail"
import { getAllChats, getChatMessages } from "../../store/chatSlice"
import { useEffect } from "react"


export const Auth = () => {
    const { user } = useSelector(state => state.auth)
    const { allChats, currentChatId } = useSelector(state => state.chats)
    const { textMessages } = useSelector(state => state.assistant)
    const dispatch = useDispatch()
    useEffect(() => {
        if (user && allChats && textMessages.length === 0) {
            if(allChats.chats.length === 0) {
                dispatch(getAllChats(user.uid))               
            } 
            else {
               dispatch(getChatMessages(currentChatId || allChats.chats?.find(chat => chat.role === "assistant").chat_id))
            } 
            
        }
    }, [dispatch, user, allChats, textMessages, currentChatId])
    return (
        <>
            <Header />
            <Routes>
                {!user || user?.emailVerified ? <>
                    <Route path='/' element={<Main />} />
                    <Route path='/bots' element={<Bots />} />
                    <Route path='/mobileapp' element={<MobileApp />} />
                    <Route path='/webassistant' element={<WebAssistant />} />
                    <Route path='/aboutus' element={<AboutUs />} />
                    <Route path='/contacts' element={<Contacts />} />
                    <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                    <Route path='/termsofuse' element={<TermsOfUse />} />
                </>
                    : <Route path='*' element={<VerifyEmail />} />
                }
            </Routes>
        </>

    )
}