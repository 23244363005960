import { useNavigate } from 'react-router-dom'
import s from './NavigationBar.module.scss'
import {ReactComponent as Arrow } from '../../assets/Arrow.svg'

export const NavigationBar = ({mainPage, pageName, botsRole = null}) => {
    const navigate = useNavigate()
    const navigateToMain = () => {
        navigate('/')
    }
    return (
        <div className={s.navigation}>
            <span className={s.main_link} onClick={navigateToMain}>{mainPage}</span>
            <span className={s.slash}>/</span>
            <span 
                className={botsRole ? `${s.page_name} ${s.inviz_name}` : s.page_name}>
                {pageName}
            </span>
            <span className={s.bots_role}>{botsRole}</span>            
            <Arrow onClick={navigateToMain} className={s.arrow}/>
        </div>
    )
}