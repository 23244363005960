import s from './BotsItem.module.scss'

export const BotsItem = ({ link, src, name, dev }) => {

    return (
        <a className={s.bots_link} target='blank' href={link}>
            <div className={`${s.item} ${link === null ? s.inDev : ''}`}>
                <div className={s.icon}>
                    <img src={src} alt="" />
                </div>
                <div className={s.text}>
                    <div className={s.name}>
                        {name}
                    </div>
                    <div className={s.dev}>
                        {dev}
                    </div>
                </div>
            </div>
        </a>

    )
}