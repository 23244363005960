import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const fetchText = createAsyncThunk(
  'assistant/fetchText',
  async (text, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState()
      const response = await fetch('https://ml1.ssrlab.by/api/text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: text,
          voice_type: 'kiryl',
          chat_id: state.chats.currentChatId || state.chats.allChats?.chats?.find(chat => chat.role === "assistant").chat_id,
          role: state.assistant.botsRole.id,
        }),
      })
      dispatch(resetErrorMessage)
      if (!response.ok) {

        throw new Error(response.statusText);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchVoice = createAsyncThunk(
  'assistant/fetchVoice',
  async (audioBlob, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState()
      const formData = new FormData();
      formData.append('audio_file', audioBlob)
      formData.append('voice_type', 'kiryl')
      formData.append('role', state.assistant.botsRole.id)
      formData.append('chat_id', state.chats.currentChatId || state.chats.allChats?.chats?.find(chat => chat.role === "assistant").chat_id)
      const response = await fetch('https://ml1.ssrlab.by/api/voice', {
        method: 'POST',
        body: formData,
      })
      dispatch(resetErrorMessage)
      if (!response.ok) {

        throw new Error(response.statusText);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getRoles = createAsyncThunk(
  'assistant/getRoles',
  async (rejectWithValue) => {
    try {
      const response = await fetch(`https://ml1.ssrlab.by/api/roles`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        }
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const assistantSlice = createSlice({
  name: 'assistant',
  initialState: {
    error: null,
    status: null,
    textMessages: [],
    allRoles: null,
    botsRole: {
      id: 'assistant'
    },
  },
  reducers: {
    resetErrorMessage: (state) => {
      state.error = null
    },
    setTextMessages: (state, action) => {
      const newMessage = {        
        text: action.payload.text,
        audio: action.payload.audio,
        isAnswer: action.payload.isAnswer,
      };
      state.textMessages.push(newMessage)
    },
    resetChatMessages: (state) => {
      state.textMessages = []
    },
    setAudioVoice: (state, action) => {
      const newMessage = {
        audio: action.payload,
        isAnswer: false,
      };
      state.textMessages.push(newMessage)
    },
    setBotsRole: (state, action) => {
      state.botsRole = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchText.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(fetchText.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const newMessage = {
          text: action.payload.text,
          audio: action.payload.audio,
          isAnswer: true,
        };
        state.textMessages.push(newMessage);
      })
      .addCase(fetchText.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchVoice.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(fetchVoice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const newMessage = {
          text: action.payload.text,
          audio: action.payload.audio,
          isAnswer: true,
        };
        state.textMessages.push(newMessage);
      })
      .addCase(fetchVoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getRoles.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allRoles = action.payload.roles
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
  },
})

export const {
  setTextMessages,
  setAudioVoice,
  resetChatMessages,
  resetErrorMessage,
  setBotsRole
} = assistantSlice.actions
export default assistantSlice.reducer

