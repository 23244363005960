import s from './ForgotPassword.module.scss'
import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Arrow } from '../../assets/Arrow.svg';
import { useState } from 'react';
import { FirebaseClient } from '../../FirebaseClient/FirebaseClient';
import { useTranslation } from 'react-i18next';


export const ForgotPassword = ({setIsSignIn, setIsOpen}) => {
    const [emailLog, setEmail] = useState('')
    const { t } = useTranslation()
    const firebase = new FirebaseClient()
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        await firebase.sendPasswordReset(emailLog)
        setIsSignIn('emailIsSend')
    }
    const closeWindow = () => {
        setIsOpen(false)
        setTimeout(() => setIsSignIn('signIn'), 300)        
    }
    return (
        <div className={s.forgot}>
            <div className={s.button_line}>
                <Arrow onClick={() => setIsSignIn('signIn')}  className={s.arrow}/>
                <Cross onClick={() => closeWindow()} />
            </div>
            <div className={s.title}>
                {t('forgotPassword')}
            </div>
            <div className={s.text}>
                {t('dontWorry')}
            </div>
            <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.input_container}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={emailLog}
                        onChange={handleEmailChange}
                        placeholder="example@gmail.com"
                    />
            </div>
            <button className={s.submit_button} type="submit">
                {t('send')}
            </button>
            </form>
        </div>
    )
}
