import { useTranslation } from 'react-i18next'
import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './AboutUs.module.scss'
import { Footer } from '../../Components/Footer/Footer'
import { MainButton } from '../../Components/MainButton/MainButton'


export const AboutUs = () => {

    const { t } = useTranslation()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return (
        <section className={s.wrapper}>
            <div className={s.about_container}>
                <NavigationBar mainPage={t('NavigationMain')} pageName={t('aboutUs')} />
                <div className={s.about_content}>
                    <div className={s.title}>
                        {t('aboutUs')}
                    </div>
                    <div className={s.text}>
                        <div className={s.text_item}>
                            {t('aboutText1')}
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText2')}
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText3')}
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText4')}
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText5')} 
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText6')} 
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText7')} 
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText8')} 
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText9')} 
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText10')} 
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText11')}
                            <a href="https://asistent.io" target="_blank" rel="noopener noreferrer"> asistent.io</a>,
                            <a href="https://asistent.by" target="_blank" rel="noopener noreferrer"> asistent.by</a>,
                            <a href="https://xn--80a0alafb9e7c.xn--90ais" target="_blank" rel="noopener noreferrer"> асістэнт.бел</a>,
                            <a href="https://xn--80akivrbhc.xn--90ais" target="_blank" rel="noopener noreferrer"> асистент.бел</a>.
                        </div>
                        <div className={`${s.text_item}`}>
                            {t('aboutText12')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText13')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText14')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText15')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText16')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText17')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText18')}
                        </div>
                        <div className={`${s.text_item} ${s.item_list}`}>
                            {t('aboutText19')}
                        </div>
                        <div className={s.text_item}>
                            {t('aboutText20')}
                        </div>
                        <div className={s.button}>
                            <MainButton text={t('aboutButton')} route={'/webassistant'} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </section>
    )
}