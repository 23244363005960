import { useEffect, useState } from 'react'
import { ReactComponent as LoginIcon } from '../../assets/logIn.svg'
import { Modal } from '../Modal/Modal'
import s from './Login.module.scss'
import { SignIn } from '../SignIn/SignIn'
import { SignUp } from '../SignUp/SignUp'
import { ForgotPassword } from '../ForgotPassword/ForgotPassword'
import { EmailIsSend } from '../EmailIsSend/EmailIsSend'
import { useDispatch } from 'react-redux'
import { setAuthError } from '../../store/authSlice'

export const Login = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [isSignIn, setIsSignIn] = useState('signIn')
    const changeModalWindow = (window) => {
        switch (window) {
            case 'signIn':
                return <SignIn setIsSignIn={setIsSignIn} setIsOpen={setIsOpen} />
            case 'signUp':
                return <SignUp setIsSignIn={setIsSignIn} setIsOpen={setIsOpen} />
            case 'forgot':
                return <ForgotPassword setIsSignIn={setIsSignIn} setIsOpen={setIsOpen} />
            case 'emailIsSend':
                return <EmailIsSend setIsSignIn={setIsSignIn} setIsOpen={setIsOpen} />
                default:
                break
        }
    }
    useEffect(() => {
        if(!isOpen) {
            dispatch(setAuthError(null))
        }
    }, [dispatch, isOpen])
    return (
        <div>
            <LoginIcon className={s.icons_container}
                       onClick={() => setIsOpen(true)}/>
            <Modal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                setIsSignIn={setIsSignIn}
                children={
                    changeModalWindow(isSignIn)} />
        </div>
    )
}